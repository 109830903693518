import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import _ from "lodash";
import overlayFactory from "react-bootstrap-table2-overlay";
import jsPDF from "jspdf";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import FEService from "../F&ERequests/FEService";
import FERequestsType from "../FERequestsType";
import { Link } from "react-router-dom";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "../F&ERequests/ClosedRequestPDF";
import { OverlayTrigger, Popover } from "react-bootstrap";
//import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import InventoryRequestStatus from "../InventoryRequestStatus";
import { FaRegFileArchive } from "react-icons/fa";

const generatePdfDocument = async (documentData, fileName) => {
  FEService.showSpinner();
  const blob = await pdf(
    <PdfDocument title={fileName} data={documentData} />
  ).toBlob();

  //saveAs(blob, fileName);
  resolveAndDownloadBlob(blob, fileName);
  FEService.hideSpinner();
};
const resolveAndDownloadBlob = (response, filename) => {
  filename = decodeURI(filename);
  const url = window.URL.createObjectURL(
    new Blob([response], {
      type: "application/pdf",
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};
// const showRequestStatusColumn = (cell, row) => {
//   return (
//     <>
//       <span> {cell && cell.replace("PendingEVP", "Pending EVP")} </span>{" "}
//       {row.status == 5 && (
//         <PDFDownloadLink
//           document={<PdfDocument data={row} />}
//           fileName={row.description + ".pdf"}
//         >
//           {({ loading, blob, url, error }) => {
//             return !loading ? (
//               <i
//                 title="PDF Export"
//                 class="fas fa-file-pdf cursorPointer pdfDoc"
//               ></i>
//             ) : (
//               //&& FEService.hideSpinner()
//               loading && (
//                 <div class="spinner-grow spinner-grow-sm" role="status">
//                   <span class="sr-only">Loading...</span>
//                 </div>
//               )
//             );
//             //  &&
//             // FEService.showSpinner();
//           }}
//         </PDFDownloadLink>
//       )}
//       <i
//         className={`fas fa-exclamation-triangle fa-sm  reviewIcon ${
//           !row.showExclamation ? "invisible" : "visible"
//         }  `}
//       ></i>{" "}
//     </>
//   );
// };
// const currencyFormatter = (cell, row) => {
//   return (
//     <span>
//       {cell &&
//         `$ ${cell.toLocaleString(navigator.language, {
//           minimumFractionDigits: 2,
//           maximumFractionDigits: 2,
//         })}`}
//     </span>
//   );
// };

const showBugdetlines = (cell, row) => {
  let isMultiBglines = row.febudgetlines && row.febudgetlines.length > 1;
  let firstBgLine = cell.split("<br>").shift() || "";
  let secondBgLine = (isMultiBglines && cell.split("<br>")[1]) || "";
  let cutStrFirst = FEService.getStrCutLength(firstBgLine);
  let cutStrSec = FEService.getStrCutLength(secondBgLine);

  return !isMultiBglines
    ? firstBgLine
    : isMultiBglines && (
        <div className="progress-wrapper req">
          <OverlayTrigger
            overlay={
              <Popover id="popover-basic req">
                <Popover.Title as="h6">All Budget Lines</Popover.Title>
                <Popover.Content>
                  <small dangerouslySetInnerHTML={{ __html: cell }} />
                </Popover.Content>
              </Popover>
            }
          >
            <i href="#" id="requests">
              {firstBgLine.substring(0, firstBgLine.length - cutStrFirst)
                ? firstBgLine.substring(0, firstBgLine.length - cutStrFirst) +
                  "... , "
                : ""}
              {secondBgLine.substring(0, secondBgLine.length - cutStrSec)
                ? secondBgLine.substring(0, secondBgLine.length - cutStrSec) +
                  "..."
                : ""}
            </i>
          </OverlayTrigger>
        </div>
      );
};

const MyExportCSV = (props) => {
  const handleClick = async () => {
    const formData = {
      recordtype: props.requestType, // Active/InAccountReview || My/Review Requests
      isExportTable: true,
      PageNo: 1,
      PageSize: 1,
      SortOrder: "id",
      activeTab: props.activeSubHeader, // Inventory Or Requests
      isAdvanceSearchApplied: props.isAdvanceSearchApplied,
      searchCriteria: props.isAdvanceSearchApplied ? props.searchCriteria : {},
      showClosed: props.showClosed,
    };
    var response = await FEService.getDataForFEExport(formData);
    if (!_.isEmpty(response)) {
      props.onExport(response.data);
    }
  };
  return (
    <div>
      <i
        title="Export to csv"
        className="csvIconSize fas fa-file-csv"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};

const MyExportPDF = (props) => {
  const handleClick = () => {
    _html2canvas(props);
  };
  return (
    <div>
      <i
        title="Export to pdf"
        className="csvIconSize fas fa-file-pdf pl-1"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};
const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Requests Found!</h5>
    </div>
  </div>
);
const Empty = () => <div />;

const getExportFileName = (tblType, filetype) => {
  if (tblType === FERequestsType.MyRequests) {
    return `${Date.now()}_MyRequestsList.${filetype}`;
  } else if (tblType == FERequestsType.ApprovedReviewRequests) {
    return `${Date.now()}_ApprovedReviewRequests.${filetype}`;
  } else if (tblType == FERequestsType.SubmittedReviewRequests) {
    return `${Date.now()}_SubmittedReviewRequests.${filetype}`;
  } else if (tblType == FERequestsType.PendingEVPReviewRequests) {
    return `${Date.now()}_PendingReviewRequests.${filetype}`;
  }
};
const _html2canvas = async (allprops) => {
  let filename = getExportFileName(allprops.requestType, "pdf");
  const formData = {
    recordtype: allprops.requestType, // Active/InAccountReview || My/Review Requests
    isExportTable: true,
    PageNo: 1,
    PageSize: 10,
    SortOrder: "id",
    activeTab: allprops.activeSubHeader, // Inventory Or Requests
    isAdvanceSearchApplied: allprops.isAdvanceSearchApplied,
    searchCriteria: allprops.isAdvanceSearchApplied
      ? allprops.searchCriteria
      : {},
    showClosed: allprops.showClosed,
  };
  var response = await FEService.getDataForFEExport(formData);
  if (!_.isEmpty(response)) {
    var tableBody = response.data.map((val) => {
      var listRow = [];
      listRow.push(val.userName);
      listRow.push(val.description);
      listRow.push(val.requesterName);
      listRow.push(val.budgetLineName);
      listRow.push(val.categoryName);
      listRow.push(val.typeName);
      listRow.push(val.statusName);
      return listRow;
    });
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "USER",
          "DESCRIPTION",
          "REQUESTER",
          "BUDGET",
          "CATEGORY",
          "TYPE",
          "STAUS",
        ],
      ],
      body: tableBody,
      tableWidth: "auto",
      styles: { cellPadding: 0.7, fontSize: 8 },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },

        // etc
      },
    });
    doc.save(filename);
  }
};
//// Starting Point of the Component
const RequestsListTable = (props) => {
  const {
    listData,
    page,
    sizePerPage,
    handleTableChange,
    totalSize,
    loading,
    requestType,
    viewRequest,
    viewFiles,
    isAdvanceSearchApplied,
    searchCriteria,
    activeSubHeader,
    showClosed,
    rowEvents,
    removeEquipmentRequest,
    markRequestArchive,
  } = props;
  const showRequestStatusColumnWithPdf = (cell, row) => {
    return (
      <>
        <div className="d-inline-block text-truncate categoryFN">
          <div className="actionIcons">
            <span> {cell && cell.replace("PendingEVP", "Pending EVP")} </span>{" "}
            {row.status == InventoryRequestStatus.Closed && (
              <i
                title="PDF Export"
                className="fas fa-file-pdf cursorPointer pdfDoc"
                onClick={async () =>
                  await generatePdfDocument(
                    row,
                    row.description + "-" + new Date().getTime() + ".pdf"
                  )
                }
              />
            )}
            {requestType == FERequestsType.MyRequests &&
              AuthService.canDelete(PermissionFeature.FETrackerRequest) &&
              (row.status == InventoryRequestStatus.Requested ||
                row.status == InventoryRequestStatus.PendingEVP ||
                row.status == InventoryRequestStatus.Draft) && (
                <i
                  className="text-danger fas fa-trash c-pointer pl-1 pr-1"
                  title="Delete"
                  onClick={() => removeEquipmentRequest(row)}
                />
              )}
            {requestType == FERequestsType.MyRequests &&
              row.status == InventoryRequestStatus.Denied &&
              row.isarchived == false && (
                <i
                  className="fa fa-archive c-pointer pl-1 pr-1"
                  aria-hidden="true"
                  title="Archive"
                  onClick={() => markRequestArchive(row)}
                />
              )}
            {requestType == FERequestsType.MyRequests &&
              row.status == InventoryRequestStatus.Denied &&
              row.isarchived == true && (
                <FaRegFileArchive
                  id="fileArchive"
                  size={14}
                  title="Archived"
                  className="alreadyArchieved"
                  disabled
                />
              )}
            <i
              className={`fas fa-exclamation-triangle fa-sm  reviewIcon ${
                !row.showExclamation ? "invisible" : "visible"
              }  `}
            />
          </div>
        </div>
      </>
    );
  };
  const RemotePagination = (
    data,
    page,
    sizePerPage,
    onTableChange,
    totalSize,
    showTotal,
    loading,
    requestType,
    viewRequest,
    viewFiles,
    isAdvanceSearchApplied,
    searchCriteria,
    activeSubHeader,
    showClosed,
    rowEvents
  ) => (
    <div
      className={`Equip_Requests_tbl_${requestType}`}
      id={`Requests_${requestType}_Records`}
      key={`Requests_${requestType}_Records_Parent`}
    >
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns()}
        exportCSV={{
          fileName: getExportFileName(requestType, "csv"),
          exportAll: false,
          onlyExportFiltered: false,
        }}
      >
        {(props) => (
          <>
            <span className="d-flex justify-content-end">
              {!_.isEmpty(data) && (
                <>
                  <MyExportCSV
                    requestType={requestType}
                    isAdvanceSearchApplied={isAdvanceSearchApplied}
                    searchCriteria={searchCriteria}
                    activeSubHeader={activeSubHeader}
                    showClosed={showClosed}
                    {...props.csvProps}
                  />
                  <MyExportPDF
                    requestType={requestType}
                    isAdvanceSearchApplied={isAdvanceSearchApplied}
                    searchCriteria={searchCriteria}
                    activeSubHeader={activeSubHeader}
                    showClosed={showClosed}
                  />
                </>
              )}
            </span>

            <BootstrapTable
              hover
              remote
              loading={loading}
              keyField="id"
              pagination={paginationFactory({
                paginationTotalRenderer: TableCustomTotal,
                page,
                sizePerPage,
                totalSize,
                showTotal,
                sizePerPageList: [
                  {
                    text: "10",
                    value: 10,
                  },
                  {
                    text: "25",
                    value: 25,
                  },
                  {
                    text: "30",
                    value: 30,
                  },
                  {
                    text: "50",
                    value: 50,
                  },
                  {
                    text: "All",
                    value: totalSize,
                  },
                ],
              })}
              // onDataSizeChange={dataSizeChange}
              //  expandRow={expandRow}
              onTableChange={(type, _ref) =>
                onTableChange(type, _ref, requestType)
              }
              overlay={overlayFactory({
                spinner: true,
                styles: {
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(40, 45, 42, 0.19)",
                  }),
                },
              })}
              noDataIndication={() => {
                return loading === false ? <NoDataIndication /> : <Empty />;
              }}
              rowEvents={rowEvents}
              rowClasses="c-pointer"
              {...props.baseProps}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
  const columns = () => [
    {
      text: "User",
      dataField: "userName",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FERequestUser),
      formatter: (cellContent, row) => {
        return (
          <div>
            <Link
              to={{
                pathname: "/directory/userAction/" + row.user,
              }}
            >
              {cellContent}
            </Link>
          </div>
        );
      },
    },
    {
      text: "Description",
      dataField: "description",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FERequestDescription),
    },
    {
      text: "Requester",
      dataField: "requesterName",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FERequestRequester),
    },
    {
      text: "Budget Line",
      dataField: "budgetLineFullName",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
      hidden: !AuthService.canView(PermissionFeature.FERequestBudgetID),
      formatter: (cell, row) => {
        return <div>{showBugdetlines(cell, row)}</div>;
      },
    },
    {
      text: "Category",
      dataField: "categoryFullName",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FERequestCategory),
    },
    {
      text: "Type",
      dataField: "typeName",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FERequestType),
    },
    // {
    //   text: "Cost",
    //   dataField: "cost",
    //   hidden: true,
    //   sort: true,
    //   formatter: currencyFormatter,
    //   csvExport: false,
    // },

    {
      text: "Status",
      dataField: "statusName",
      hidden: !AuthService.canView(PermissionFeature.FERequestStatus),
      sort: true,
      formatter: (cell, row) => {
        return <div>{showRequestStatusColumnWithPdf(cell, row)}</div>;
      },
    },
    {
      dataField: "equipmentid",
      sort: true,
      hidden: true,
      csvExport: false,
    },
    {
      dataField: "id",
      sort: true,
      hidden: true,
      csvExport: false,
    },
  ];
  return (
    <>
      {RemotePagination(
        listData,
        page,
        sizePerPage,
        handleTableChange,
        totalSize,
        true,
        loading,
        requestType,
        viewRequest,
        viewFiles,
        isAdvanceSearchApplied,
        searchCriteria,
        activeSubHeader,
        showClosed,
        rowEvents
      )}
    </>
  );
};

RequestsListTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

RequestsListTable.defaultProps = {
  listData: [],
};

export default RequestsListTable;
