import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import $ from "jquery";
import _ from "lodash";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import authAxios from "../../Shared/auth-header";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import axios from "../../Shared/auth-header";
import AuthService from "../../Auth/AuthService.js";
import Services from "../RFTeamsService";
import RFService from "../RFService";
import TypeModal from "../../Shared/TypeModal";
import * as $AB from "jquery";
import RegionModal from "../../Shared/RegionModal";
import { Row, Col } from "react-bootstrap";
import actions from "../../../store/facility/action";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import "./addfacility.css";
import {
  oshaTierOptions,
  oshaOwnerOptions,
  facilityLevelOptions,
  facilityRegionsOptions,
  facilityTypeOptions,
  SRF12Numbers,
  facilityStatusOptions,
} from "./facilitySelectOptions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import PermissionFeature from "../../Permissions/PermissionFeature";
const { SearchBar } = Search;

class Facilities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 20,
      totalCount: 0,
      employerList: [],
      showLevel: true,
      Keyword: "",
      filter: "",
      typefilter: 0,
      rows: [],
      facilityTypes: [],
      totalCount: "",
      showdata: [],
      region: this.props.region ? this.props.region : "",
      keyword: this.props.location ? this.props.location.keyword : "",
      dropdownOpen: false,
      delId: 0,
      hiddenRowKeys: [23, 25],
      code: "",
      description: "",
      afdfilename: "",
      regionC: "",
      id: 0,
      regionid: 0,
      facilitytype: "",
      facilitytypeid: 0,
      facilitystatus: "Represented",
      lm4No: "",
      errorText: "",
      email: "",
      bulkProgress: true,
      isDropDownsValid: false,
      SortOrder: "CodeAsc",
    };
    this.toggle = this.toggle.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.ViewAllLink = this.ViewAllLink.bind(this);
    this.createFacilityTeam = this.createFacilityTeam.bind(this);
    this.preventDefaultCustom = this.preventDefaultCustom.bind(this);
    this.sizePerPageChange = this.sizePerPageChange.bind(this);
    this.getFacilityTypes = this.getFacilityTypes.bind(this);
    this.loadEmployerList = this.loadEmployerList.bind(this);
    this.showLevel = this.showLevel.bind(this);
    this.updateFacilityType = (args) => {
      this.setState({
        facilitytype: args.code,
        facilitytypeid: args.id,
      });
      $AB(".cancelModal").trigger("click");
    };
    this.regionMethods = () => {
      this.updateRegion = (args) => {
        this.setState({ regionC: args.code, regionid: args.id });
        $AB(".cancelModal").trigger("click");
      };

      return this;
    };
    this.showNested = this.props.region ? "d-none" : "";
    this.onCSVClick = this.onCSVClick.bind(this);
    this.onPDFClick = this.onPDFClick.bind(this);
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (e.target.className.indexOf("del") < 0) {
          if (AuthService.canView(PermissionFeature.Facility)) {
            if (this.props.region) {
              this.props.his.push({
                pathname: `/directory/facility/view/${row.id}`,
                type: "edit",
                uid: row.id,
                backRef: { uid: row.id },
                keyword: $("#Keyword").val(),
                page: this.state.page,
              });
            } else {
              this.props.history.push({
                pathname: `/directory/facility/view/${row.id}`,
                type: "edit",
                uid: row.id,
                keyword: $("#Keyword").val(),
                page: this.state.page,
                // backRef: {
                //     keyword: $("#Keyword").val(),
                //     page: this.state.page
                // }
              });
            }
          }
        }
      },
    };
    this.columns = this.columns.bind(this);
    this.RemotePagination = this.RemotePagination.bind(this);
    this.hiddenRows = [1];
    //const customTotal = (from, to, size) => (
    //  <span
    //    className="react-bootstrap-table-pagination-total"
    //    style={{ float: "left" }}
    //  >
    //    {from}
    //    {" - "}
    //    {to} of {size} {"   "}
    //    <b> Show: </b>
    //  </span>
    //);
  }
  preventDefaultCustom = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  async loadEmployerList() {
    let result = await RFService.EmployerList();
    if (result != null) {
      this.setState({ employerList: result });
    }
  }
  sizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPage: sizePerPage });
  };
  RemotePagination = (data, page, sizePerPage, showTotal, rowEvents) => {
    const sizePerPageList = [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "All",
        value: data && data.length,
      },
    ];
    let paginationOptions = {
      totalSize: data ? data.length : 0,
      showTotal,

      // hidePageListOnlyOnePage: true,
      onSizePerPageChange: this.sizePerPageChange,
      paginationTotalRenderer: TableCustomTotal,
      sizePerPage,
      sizePerPageList,
      page,
      //hidePageListOnlyOnePage: true
    };

    return (
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <div>
            <BootstrapTable
              keyField="id"
              data={data}
              columns={this.columns()}
              {...paginationTableProps}
              rowEvents={rowEvents}
              id="facilitiesListing"
            />
          </div>
        )}
      </PaginationProvider>
    );
  };
  handleChange = async (evt) => {
    await this.setState({ [evt.target.name]: evt.target.value });
    this.showLevel();
  };
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  handleChangeSearch = (evt) => {
    this.preventDefaultCustom(evt);
    this.setState({ Keyword: evt.target.value });
  };
  columns = () => {
    return [
      {
        text: "Facility ID",
        dataField: "code",
        sort: true,
        filterValue: (cell, row, ro) => {
          return false;
        },
        formatExtraData: this.state.bulkProgress,
        hidden: !AuthService.canView(PermissionFeature.FacilityCode),
        formatter: (cell, row) => {
          if (AuthService.canView(PermissionFeature.FacilityCode)) {
            return (
              <>
                {cell}{" "}
                {AuthService.canView(PermissionFeature.TeamsTeams) &&
                row.teamid != null &&
                row.teamid != -1 ? (
                  <a
                    alt="show Teams"
                    onClick={(e) => {
                      this.preventDefaultCustom(e);
                    }}
                    href={"/permissions/teams/teamDetail/" + row.teamid}
                  >
                    <span className="fas fa-sm fa-users"></span>
                  </a>
                ) : (
                  AuthService.canAdd(PermissionFeature.TeamsTeams) &&
                  !this.state.bulkProgress && (
                    <span
                      alt="Create Team"
                      onClick={(e) => {
                        this.createFacilityTeam(e, row.id);
                      }}
                      className="text-primary fas fa-sm fa-plus-circle"
                    ></span>
                  )
                )}
              </>
            );
          }
          return "";
        },
      },
      {
        text: "Facility Name",
        dataField: "description",
        sort: true,
        searchable: false,
        //style: { width: 400 },
        hidden: !AuthService.canView(PermissionFeature.FacilityDescription),
        formatter: (cell, row) => {
          if (AuthService.canView(PermissionFeature.FacilityDescription))
            return cell;
          else return "";
        },
      },
      {
        text: "FacRep Name",
        dataField: "facRPName",

        sort: true,
        hidden: !AuthService.canView(PermissionFeature.FacilityRepresentatives),

        hidden: !AuthService.canView(PermissionFeature.FacilityRepresentatives),
        formatter: (cell, row) => {
          if (
            AuthService.canView(PermissionFeature.FacilityRepresentatives) &&
            row.facRPName != null
          )
            return (
              <Link
                onClick={this.preventDefaultCustom}
                to={{
                  pathname: "/directory/userAction/" + row.facRPId,
                }}
              >
                {cell}
              </Link>
            );
          else return "";
        },
      },
      {
        text: "FacRep Email",
        dataField: "facRPEmail",
        sort: true,
        hidden: !AuthService.canView(
          PermissionFeature.FacilityRepresentativesEmail
        ),
        formatter: (cell, row) => {
          if (
            AuthService.canView(PermissionFeature.FacilityRepresentativesEmail)
          ) {
            return (
              <a
                target="_blank"
                href={"mailto:" + cell}
                onClick={this.preventDefaultCustom}
              >
                {cell}
              </a>
            );
          } else {
            return "";
          }
        },
      },
      {
        text: "FacRep Phone",
        dataField: "facRPPhone",
        sort: true,
        hidden: !AuthService.canView(
          PermissionFeature.FacilityRepresentativesPhone
        ),
        formatter: (cell, row) => {
          if (
            AuthService.canView(PermissionFeature.FacilityRepresentativesPhone)
          ) {
            return (
              <a
                target="_blank"
                href={"tel:" + cell}
                onClick={this.preventDefaultCustom}
              >
                {cell}
              </a>
            );
          } else {
            return "";
          }
        },
      },
    ];
  };

  async createFacilityTeam(e, id) {
    this.preventDefaultCustom(e);
    let data = await Services.CreateFacilityTeam(id);
    if (data) {
      this.getFacilitites();
    }
  }
  // hideFacilities(data) {
  //   let indexArray = [];
  //   data.filter((el, i) => {
  //     if (!el.permissions.canView) {
  //       indexArray.push(i);
  //     }
  //   });
  // }
  getFacilitites() {
    let keyword = this.state.keyword;
    let page = this.state.page;
    if (this.props.his) {
      keyword = this.props.his.location.keyword
        ? this.props.his.location.keyword
        : keyword;
      page = this.props.his.location.page ? this.props.his.location.page : page;
    } else if (this.props.location) {
      keyword = this.props.location.keyword
        ? this.props.location.keyword
        : keyword;
      page = this.props.location.page ? this.props.location.page : page;
    }
    $(".loading").show();
    let data = {
      RegionCode: this.state.region || "",
      Keyword: keyword,
      PageNo: page,
      PageSize: this.state.sizePerPage,
      OperatorType: "AND",
      SortOrder: "CodeAsc",
    };
    authAxios.post("/api/Facilities/GetFacilities", data).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          rows: data.data,
          totalCount: data.totalCount,
        });
        if (!this.props.region) {
          $("Keyword,.add-fac,.edit,.del").removeClass("d-none");
        }
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  }
  async getFacilityTypes() {
    let result = await RFService.FacilityTypes();
    if (result != null) {
      await this.setState({ facilityTypes: result });
    }
  }

  refreshAddFacilityModal() {
    $("#editFacilityCloseBtn").click();
    this.setState({
      code: "",
      description: "",
      regionC: "",
      id: 0,
      regionid: 0,
      facilitytype: "",
      facilitytypeid: 0,
    });
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    evt.target.className += " was-validated";

    const allrefs = this.refs;
    //allrefs.MateritalUIform.resetValidations();
    const isSubmit = allrefs.MateritalUIform.submit();

    if (evt.target.checkValidity() === false) {
      return false;
    }

    //debugger
    const selectedRegion =
      this.state.fac_regionid &&
      this.props.facilityDashboardState.facilityRegionOptions.find((el) => {
        return el.description === this.state.fac_regionid;
      }).id;
    const selectedFacilityType =
      this.state.fac_typeid &&
      this.props.facilityDashboardState.facilityTypeOptions.find((el) => {
        return el.description === this.state.fac_typeid;
      }).id;

    allrefs.MateritalUIform.isFormValid()
      .then((isTrue) => {
        //console.log(isTrue);
        if (!isTrue) {
          return false;
        } else {
          if (this.state.latitude < -90 || this.state.latitude > 90) {
            toast.error(
              "Latitude must be between -90 and 90 degrees inclusive."
            );
            return false;
          } else if (
            this.state.longitude < -180 ||
            this.state.longitude > 180
          ) {
            toast.error(
              "Longitude must be between -180 and 180 degrees inclusive."
            );
            return false;
          }

          const data = {
            Id: this.state.id,
            code: this.state.code,
            description: this.state.description,
            region: this.state.regionC,
            regionid: this.state.regionid,
            facilitytype: this.state.facilitytype,
            facilitytypeid: this.state.facilitytypeid,
            afdfilename: this.state.afdfilename,
            lmivnumber: this.state.lm4No,
            level: this.state.level,
            srfrxiistatus: this.state.srfrxiistatus,
            owner: this.state.oshamaintainer,
            status: this.state.facilitystatus,
            oshatier: this.state.oshatier,
            regionid: selectedRegion,
            facilitytypeid: selectedFacilityType,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
          };
          axios
            .post("/api/Facilities/SaveFacility", data)
            .then(({ data }) => {
              if (data.status && data.status == "Success") {
                toast.success(data.message);
                //this.props.history.push({ pathname: "/directory/facility", tab: Facilities, name: "Facilities" });
                this.getFacilitites();
              } else {
                toast.error(data.message);
              }
              $("#addFacilityForm").removeClass("was-validated");
              this.refreshAddFacilityModal();
              //$('.modal-backdrop').hide();
              this.props.forcelyCloseModal("AddFacilityModal");
            })
            .catch(function(error) {
              console.log(error);
              $("#addFacilityForm").removeClass("was-validated");
              this.refreshAddFacilityModal();
              // $('.modal-backdrop').hide();
              this.props.forcelyCloseModal("AddFacilityModal");
            });
        }
      })
      .catch((message) => {
        console.log(message);
      });
  };

  componentWillMount() {
    this.setState({ bulkProgress: this.props.bulkProgress });
    this.getFacilitites();
  }
  async componentDidUpdate(prevProps) {
    if (this.props.refreshRF) {
      this.getFacilitites();
      this.props.setFalseRefreshRF();
    }
    if (this.props.bulkProgress != prevProps.bulkProgress) {
      await this.setState({ bulkProgress: this.props.bulkProgress });
      this.getFacilitites();
    }
  }
  componentDidMount() {
    let keyword = this.state.keyword;
    let page = this.state.page;
    if (this.props.his) {
      keyword = this.props.his.location.keyword
        ? this.props.his.location.keyword
        : keyword;
      page = this.props.his.location.page ? this.props.his.location.page : page;
    } else if (this.props.location) {
      keyword = this.props.location.keyword
        ? this.props.location.keyword
        : keyword;
      page = this.props.location.page ? this.props.location.page : page;
    }
    this.getFacilityTypes();
    this.setState({
      Keyword: keyword,
      page: page,
    });
    this.loadEmployerList();
    $("#Keyword").val(keyword);
    // this.props.location.uid = this.props.his.location.uid;
    // this.setState({
    //   id: this.props.his.location.uid
    // });
  }
  componentWillUnmount() {
    if (this.props.his && this.props.his.action === "POP") {
      if (
        this.props.his.location.pathname.includes("/directory/facility/view")
      ) {
        this.props.his.push({
          pathname: `/directory/facility/view/${this.props.his.location.pathname.replace(
            /[^\d.]/g,
            ""
          )}`,
          type: "edit",
          backRef: {
            uid: this.props.his.location.pathname.replace(/[^\d.]/g, ""),
          },
          keyword: $("#Keyword").val(),
          page: this.state.page,
        });
      }
    } else if (this.props.history && this.props.history.action === "POP") {
      if (
        this.props.history.location.pathname.includes(
          "/directory/facility/view"
        )
      ) {
        this.props.history.push({
          pathname: `/directory/facility/view/${this.props.history.location.pathname.replace(
            /[^\d.]/g,
            ""
          )}`,
          type: "edit",
          backRef: {
            uid: this.props.history.location.pathname.replace(/[^\d.]/g, ""),
          },
          keyword: $("#Keyword").val(),
          page: this.state.page,
        });
      }
    }
  }

  ViewAllLink = (event) => {
    event.preventDefault();
    const url = `/api/Facilities/GetFacilities`;

    const formData = {
      RegionCode: this.state.region || "",
      PageNo: 1,
      PageSize: this.state.totalCount,
      Keyword: this.state.Keyword,
      OperatorType: "AND",
      SortOrder: this.state.SortOrder,
    };

    $(".loading").show();

    authAxios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          rows: data.data,
          totalCount: data.totalCount,
          page: 1,
          sizePerPage: data.totalCount,
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  };
  onClickHandler = (event) => {
    const value = event.target.innerHTML;
    this.setState({ facilityStatus: value });
  };
  async changeValue(e, type) {
    //const value = e.target.value;

    const value = e.target.innerText;

    if (value != undefined) {
      const nextState = {};
      nextState[type] = value;

      await this.setState(nextState);
    } else {
      const emptyState = {};
      emptyState[type] = "";
      await this.setState(emptyState);
    }
    this.showLevel();
  }
  showLevel = async () => {
    let facType = this.state.fac_typeid;

    let code = this.state.code;
    if (
      facType == "Federal Contract Tower (FCT)" ||
      facType == "DoD Facility" ||
      (code || "").toUpperCase() == "FS1" ||
      (code || "").toUpperCase() == "FS2" ||
      (code || "").toUpperCase() == "FS3" ||
      (code || "").toUpperCase() == "FS4"
    ) {
      await this.setState({ showLevel: false, level: "" });
    } else {
      await this.setState({ showLevel: true });
    }
  };
  handleMaterialSubmit = () => {
    // your submit logic
    // evt.preventDefault();
    // evt.target.className += " was-validated";
    // if (evt.target.checkValidity() === false) {
    //   return false;
    // }
  };
  facilityFilters(rows) {
    var firstsearch = "",
      lastsearch = "";
    if (this.state.Keyword != null && this.state.Keyword != "") {
      lastsearch = this.state.Keyword;
      if (this.state.Keyword.indexOf(" ") > 0) {
        //spliting search term for first and last name
        let name = this.state.Keyword.split(" ");
        firstsearch = name[0];
        lastsearch = name[1];
      }
    }
    let filter = rows.filter((itemQ) => {
      let lastname = "",
        firstname = "";
      if (itemQ.facRPName) {
        lastname = itemQ.facRPName;
        if (itemQ.facRPName.indexOf(" ") > 0) {
          let name = itemQ.facRPName.split(" ");
          firstname = name[0];
          lastname = name[1];
        }
      }
      return (
        (this.state.filter && this.state.filter != ""
          ? _.trim(_.toLower(this.state.filter)) ==
            _.trim(_.toLower(itemQ.status))
          : true) &&
        (this.state.typefilter && this.state.typefilter != 0
          ? this.state.typefilter == itemQ.facilityTypeId
          : true) &&
        (_.toLower(itemQ.code).includes(_.toLower(this.state.Keyword || "")) ||
          _.toLower(itemQ.description).includes(
            _.toLower(this.state.Keyword || "")
          ) ||
          _.toLower(itemQ.region).includes(
            _.toLower(this.state.Keyword || "")
          ) ||
          _.toLower(itemQ.facRPEmail).includes(
            _.toLower(this.state.Keyword || "")
          ) ||
          _.toLower(itemQ.facRPPhone).includes(
            _.toLower(this.state.Keyword || "")
          ) ||
          (firstsearch
            ? _.toLower(lastname).startsWith(_.toLower(lastsearch || "")) &&
              _.toLower(firstname).startsWith(_.toLower(firstsearch))
            : _.toLower(lastname).startsWith(_.toLower(lastsearch || ""))))
      );
    });
    if (JSON.stringify(filter) != JSON.stringify(this.state.showdata)) {
      this.setState({ showdata: filter });
    }
  }
  ConvertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str =
      "Facility ID,Region ID,Facility Name,FacRep Name,FacRep Email,FacRep Phone\r\n";

    for (var i = 0; i < array.length; i++) {
      var line = "";

      line += (array[i].code == null ? "" : array[i].code) + ",";
      line += (array[i].region == null ? "" : array[i].region) + ",";
      line += (array[i].description == null ? "" : array[i].description) + ",";
      line += (array[i].facRPName == null ? "" : array[i].facRPName) + ",";
      line += (array[i].facRPEmail == null ? "" : array[i].facRPEmail) + "\t,";
      line += array[i].facRPPhone == null ? "" : array[i].facRPPhone;

      str += line + "\r\n";
    }

    return str;
  }
  onCSVClick = async () => {
    $(".loading").show();
    const dataTable = document.getElementById("pdfdiv");
    //const btnExportToCsv = document.getElementById("btnExportToCsv");
    //const exporter = new TableCSVExporter(dataTable);
    const csvOutput = this.ConvertToCSV(this.state.showdata);
    const csvBlob = new Blob([csvOutput], {
      type: "text/csv",
    });
    const blobUrl = URL.createObjectURL(csvBlob);
    const anchorElement = document.createElement("a");

    anchorElement.href = blobUrl;
    anchorElement.download = "Facilities.csv";
    anchorElement.click();
    $(".loading").hide();
  };

  onPDFClick = async () => {
    //debugger;

    $(".loading").show();
    this._html2canvas(this.state.showdata);
    $(".loading").hide();
  };

  _html2canvas = (data) => {
    var tableBody = data.map((val) => {
      var listRow = [];
      // {"id":170,"code":"FLL","description":"Fort Lauderdale Tower","region":"NSO","status":"Represented","isActive":true,"teamid":455,"facRPId":35786,"facRPName":"Kyle Kirchheiner","facRPEmail":"kkirchheiner@gmail.com","facRPPhone":"(954) 410-9975","permissions":null}
      listRow.push(val.code);
      listRow.push(val.region);
      listRow.push(val.description);
      listRow.push(val.facRPName);
      listRow.push(val.facRPEmail);
      listRow.push(val.facRPPhone);

      //  listRow.push(val.org_title);
      // listRow.push(val.scd_date);
      // listRow.push(val.grade);
      //   listRow.push(val.hourly_salary);
      //  listRow.push(val.yearly_salary);
      return listRow;
    });
    const doc = new jsPDF();
    //autoTable(doc, { html: $(`#${tblCurrent} .react-bootstrap-table`).get(0) });
    doc.autoTable({
      head: [
        [
          "FACILITY",
          "REGION",
          "FACILITY NAME",
          "FACREP NAME",
          "FACREP EMAIL",
          "FACREP PHONE",

          //"SCD",
          //"GRADE",
          //"HOURLY SALARY",
          //"YEARLY SALARY",
        ],
      ],
      body: tableBody,
      tableWidth: "auto",
      styles: { cellPadding: 0.7, fontSize: 8 },
      columnStyles: {
        5: { cellWidth: 25 },

        // etc
      },
      //styles: { overflow: "ellipsize", cellWidth: "auto" },
      // Override the default above for the text column
      //columnStyles: { text: { cellWidth: "wrap" }, columnWidth: "20" },
      // margin: { right: 5 },
    });
    doc.save("Facilities.pdf");
  };

  render() {
    const { rows, sizePerPage, page, totalCount } = this.state;
    const showTotal = true;
    let ViewAll = null; //<button className="btn btn-primary" onClick={this.ViewAllLink}>View All Links</button>;
    this.facilityFilters(rows);
    const sizePerPageList = [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "All",
        value: totalCount,
      },
    ];
    return (
      <AUX>
        <div className="w-100 mx-auto">
          <div className="page-title-box">
            <div className="align-items-center row mx-0">
              <div className="text-center col-md-6 col-lg-6 col-xl-6 col-xs-12 col-sm-12">
                <h4 className="page-title-search">Facilities</h4>
                <form
                  role="search"
                  className="app-search mt-0"
                  onSubmit={this.handleChangeSearch}
                >
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      id="Keyword"
                      name="Keyword"
                      value={this.state.Keyword || ""}
                      onChange={this.handleChangeSearch}
                      className="form-control mb-1 w-100"
                      placeholder="Facility Search.."
                    />
                  </div>
                </form>
              </div>
              <div className="text-right col-md-6 pt-5">
                {this.state.showdata != null && this.state.showdata.length > 0 && (
                  <span className={this.state.disableIcon}>
                    <i
                      class="nav-link-font-size fas fa-file-pdf c-pointer"
                      onClick={() => this.onPDFClick()}
                    ></i>

                    <i
                      class="nav-link-font-size px-1 fas fa-file-csv c-pointer"
                      onClick={() => this.onCSVClick()}
                    ></i>
                  </span>
                )}

                <UncontrolledDropdown
                  isOpen={this.state.dropdownOpen}
                  className="inline-item py-0"
                >
                  <DropdownToggle
                    tag="a"
                    onClick={this.toggle}
                    className="nav-link-font-size c-pointer"
                  >
                    <i className={"fas fa-filter"} title={`filter status`}></i>
                  </DropdownToggle>
                  <DropdownMenu className="py-0">
                    <DropdownItem className={`dropdown-item-custom`}>
                      <UncontrolledDropdown className="inline-item py-0">
                        <DropdownToggle
                          tag="a"
                          className="nav-link-font-size c-pointer"
                        >
                          <small>
                            Status <i class="fas fa-chevron-down"></i>
                          </small>
                        </DropdownToggle>
                        <DropdownMenu className="py-0">
                          <DropdownItem
                            className={`dropdown-item-custom${
                              this.state.filter == "" ? " active" : ""
                            }`}
                            onClick={() => {
                              this.setState({ filter: "" });
                              this.toggle();
                            }}
                          >
                            None
                          </DropdownItem>
                          {facilityStatusOptions.map((x) => (
                            <DropdownItem
                              className={`dropdown-item-custom${
                                this.state.filter == x.value ? " active" : ""
                              }`}
                              onClick={async () => {
                                await this.setState({ filter: x.value });
                                this.toggle();
                              }}
                            >
                              {x.value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </DropdownItem>
                    <DropdownItem className={`dropdown-item-custom`}>
                      <UncontrolledDropdown className="inline-item py-0">
                        <DropdownToggle
                          tag="a"
                          className="nav-link-font-size c-pointer"
                        >
                          <small>
                            Type <i class="fas fa-chevron-down"></i>
                          </small>
                        </DropdownToggle>
                        <DropdownMenu className="py-0">
                          <DropdownItem
                            className={`dropdown-item-custom${
                              this.state.typefilter == 0 ? " active" : ""
                            }`}
                            onClick={() => {
                              this.setState({ typefilter: 0 });
                              this.toggle();
                            }}
                          >
                            None
                          </DropdownItem>
                          {this.state.facilityTypes.map((x) => (
                            <DropdownItem
                              className={`dropdown-item-custom${
                                this.state.typefilter == x.id ? " active" : ""
                              }`}
                              onClick={async () => {
                                await this.setState({ typefilter: x.id });
                                this.toggle();
                              }}
                            >
                              {x.value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
          <div className="col-12 mb-50">
            <div id="teamMembers">
              {!_.isEmpty(rows) &&
                this.RemotePagination(
                  this.state.showdata,
                  page,
                  sizePerPage,
                  showTotal,
                  this.rowEvents
                )}

              {/*ViewAll*/}
            </div>
          </div>
        </div>
        {/* Add Facility modal */}
        <div
          className="modal fade"
          id="AddFacilityModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddFacilityModalTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AddFacilityModalTitle">
                  {" "}
                  Add Facility{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form
                className="needs-validation"
                id="addFacilityForm"
                onSubmit={this.handleSubmit}
                noValidate
              >
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <Fragment>
                        <div className="row">
                          {AuthService.canView(
                            PermissionFeature.FacilityCode
                          ) && (
                            <div className="col-4">
                              <div className="form-group">
                                <MDBInput
                                  value={this.state.code}
                                  id="code"
                                  name="code"
                                  onChange={this.handleChange}
                                  label="Facility Code"
                                  group
                                  type="text"
                                  required
                                >
                                  <div className="invalid-feedback">
                                    Code is required.
                                  </div>
                                </MDBInput>
                              </div>
                            </div>
                          )}
                          {AuthService.canView(
                            PermissionFeature.FacilityDescription
                          ) && (
                            <div className="col-4">
                              <div className="form-group">
                                <MDBInput
                                  value={this.state.description}
                                  id="description"
                                  name="description"
                                  onChange={this.handleChange}
                                  label="Description"
                                  group
                                  type="text"
                                  required
                                >
                                  <div className="invalid-feedback">
                                    Description is required.
                                  </div>
                                </MDBInput>
                              </div>
                            </div>
                          )}

                          {AuthService.canView(
                            PermissionFeature.FacilityFAAAFDFilename
                          ) && (
                            <div className="col-4">
                              <div className="form-group">
                                <MDBInput
                                  value={this.state.afdfilename}
                                  id="afdfilename"
                                  name="afdfilename"
                                  onChange={this.handleChange}
                                  label="FAA AFD Filename"
                                  group
                                  type="text"
                                  //required
                                >
                                  <div className="invalid-feedback">
                                    File Name is required.
                                  </div>
                                </MDBInput>
                              </div>
                            </div>
                          )}
                        </div>

                        <Row>
                          {AuthService.canView(
                            PermissionFeature.FacilityLmNumber
                          ) && (
                            <Col lg={4} xl={4} sm={4} xs={4} md={4}>
                              <div className="form-group">
                                <MDBInput
                                  value={this.state.lm4No}
                                  id="lm4No"
                                  name="lm4No"
                                  onChange={this.handleChange}
                                  label="LM-4 File Number"
                                  group
                                  type="Number"
                                  //required
                                >
                                  <div className="invalid-feedback">
                                    LM-4 File No is required.
                                  </div>
                                </MDBInput>
                              </div>
                            </Col>
                          )}
                          {AuthService.canView(
                            PermissionFeature.FacilityLatitude
                          ) && (
                            <Col lg={4} xl={4} sm={4} xs={4} md={4}>
                              <MDBInput
                                value={this.state.latitude}
                                id="latitude"
                                name="latitude"
                                onChange={this.handleChange}
                                label="Latitude"
                                group
                                type="number"
                                // required
                              >
                                <div className="invalid-feedback">
                                  Latitude is required.
                                </div>
                              </MDBInput>
                            </Col>
                          )}
                          {AuthService.canView(
                            PermissionFeature.FacilityLongitude
                          ) && (
                            <Col lg={4} xl={4} sm={4} xs={4} md={4}>
                              <MDBInput
                                value={this.state.longitude}
                                id="longitude"
                                name="longitude"
                                onChange={this.handleChange}
                                label="Longitude"
                                group
                                type="number"
                                // required
                              >
                                <div className="invalid-feedback">
                                  Longitude No is required.
                                </div>
                              </MDBInput>
                            </Col>
                          )}
                        </Row>
                        <ValidatorForm
                          ref="MateritalUIform"
                          onSubmit={this.handleMaterialSubmit}
                          onError={(errors) => {
                            if (errors.length > 0) {
                              this.setState({ isDropDownsValid: false });
                              console.log(errors);
                            } else {
                              this.setState({ isDropDownsValid: true });
                            }
                          }}
                        >
                          <Row>
                            {AuthService.canView(
                              PermissionFeature.FacilityRegion
                            ) && (
                              <Col
                                lg={3}
                                xl={3}
                                sm={3}
                                xs={3}
                                md={3}
                                className="mb-4"
                              >
                                <Autocomplete
                                  id="combo-box-regionid"
                                  autoHighlight
                                  options={
                                    this.props.facilityDashboardState
                                      .facilityRegionOptions &&
                                    this.props.facilityDashboardState
                                      .facilityRegionOptions
                                  }
                                  getOptionLabel={(option) =>
                                    option.description
                                  }
                                  onChange={(e) =>
                                    this.changeValue(e, "fac_regionid")
                                  }
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      value={this.state.fac_regionid}
                                      validators={["required"]}
                                      errorMessages={["Region is required"]}
                                      label="Region"
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </Col>
                            )}
                            {AuthService.canView(
                              PermissionFeature.FacilityOwner
                            ) && (
                              <Col
                                lg={3}
                                xl={3}
                                sm={3}
                                xs={3}
                                md={3}
                                className="mb-4"
                              >
                                <Autocomplete
                                  id="combo-box-oshamaintainer"
                                  options={this.state.employerList}
                                  getOptionLabel={(option) => option.options}
                                  onChange={(e) =>
                                    this.changeValue(e, "oshamaintainer")
                                  }
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      value={this.state.oshamaintainer}
                                      //validators={["required"]}
                                      //errorMessages={[
                                      //  "Facility Employer is required",
                                      //]}
                                      label="Facility Employer"
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </Col>
                            )}

                            {AuthService.canView(
                              PermissionFeature.FacilityStatus
                            ) && (
                              <Col
                                lg={3}
                                xl={3}
                                sm={3}
                                xs={3}
                                md={3}
                                className="mb-4"
                              >
                                <Autocomplete
                                  id="combo-box-status"
                                  options={facilityStatusOptions}
                                  getOptionLabel={(option) => option.text}
                                  onChange={(e) =>
                                    this.changeValue(e, "facilitystatus")
                                  }
                                  value={
                                    this.state.facilitystatus &&
                                    this.state.facilitystatus !== "" &&
                                    this.state.facilitystatus !== null
                                      ? facilityStatusOptions.find((el) => {
                                          return (
                                            el.value ===
                                            this.state.facilitystatus
                                          );
                                        })
                                      : null
                                  }
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      label="Facility Status"
                                      value={this.state.facilitystatus}
                                      validators={["required"]}
                                      errorMessages={["Status is required"]}
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </Col>
                            )}
                          </Row>
                          <Row>
                            {AuthService.canView(
                              PermissionFeature.FacilityType
                            ) && (
                              <Col
                                lg={3}
                                xl={3}
                                sm={3}
                                xs={3}
                                md={3}
                                className="mt-2 mb-2"
                              >
                                <Autocomplete
                                  id="combo-box-facilitytypeid"
                                  options={
                                    this.props.facilityDashboardState
                                      .facilityTypeOptions &&
                                    this.props.facilityDashboardState
                                      .facilityTypeOptions
                                  }
                                  getOptionLabel={(option) =>
                                    option.description
                                  }
                                  onChange={(e) =>
                                    this.changeValue(e, "fac_typeid")
                                  }
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      label="Facility Type"
                                      value={this.state.fac_typeid}
                                      validators={
                                        this.state.showLevel
                                          ? ["required"]
                                          : null
                                      }
                                      errorMessages={[
                                        "Facility type is required",
                                      ]}
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </Col>
                            )}
                            {AuthService.canView(
                              PermissionFeature.FacilityLevel
                            ) &&
                              this.state.showLevel && (
                                <Col
                                  lg={3}
                                  xl={3}
                                  sm={3}
                                  xs={3}
                                  md={3}
                                  className="mt-2 mb-2"
                                >
                                  <Autocomplete
                                    id="combo-box-level"
                                    options={facilityLevelOptions}
                                    getOptionLabel={(option) => option.text}
                                    onChange={(e) =>
                                      this.changeValue(e, "level")
                                    }
                                    renderInput={(params) => (
                                      <TextValidator
                                        {...params}
                                        label="Facility Level"
                                        value={this.state.level}
                                        validators={["required"]}
                                        errorMessages={["Level is required"]}
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </Col>
                              )}
                            {AuthService.canView(
                              PermissionFeature.FacilityOSHATier
                            ) && (
                              <Col
                                lg={3}
                                xl={3}
                                sm={3}
                                xs={3}
                                md={3}
                                className="mt-2 mb-2"
                              >
                                <Autocomplete
                                  id="combo-box-oshatier"
                                  options={oshaTierOptions}
                                  getOptionLabel={(option) => option.text}
                                  onChange={(e) =>
                                    this.changeValue(e, "oshatier")
                                  }
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      value={this.state.oshatier}
                                      //validators={["required"]}
                                      //errorMessages={["OSHA Tier is required"]}
                                      label="OSHA Tier"
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </Col>
                            )}

                            {AuthService.canView(
                              PermissionFeature.FacilitySrfStatus
                            ) && (
                              <Col
                                lg={3}
                                xl={3}
                                sm={3}
                                xs={3}
                                md={3}
                                className="mt-2 mb-2"
                              >
                                <Autocomplete
                                  id="combo-box-srfrxiistatus"
                                  options={SRF12Numbers}
                                  getOptionLabel={(option) => option.text}
                                  onChange={(e) =>
                                    this.changeValue(e, "srfrxiistatus")
                                  }
                                  renderInput={(params) => (
                                    <TextValidator
                                      {...params}
                                      label="SRF-12"
                                      variant="standard"
                                      //value={this.state.srfrxiistatus}
                                      //validators={["required"]}
                                      errorMessages={["SRF-12 is required"]}
                                      fullWidth
                                    />
                                  )}
                                />
                              </Col>
                            )}
                          </Row>
                        </ValidatorForm>
                      </Fragment>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    id="editFacilityCloseBtn"
                  >
                    Close
                  </button>
                  <Button to="/roles" color="primary" type="submit">
                    Save Changes
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default connect(
  (state) => ({
    facilityDashboardState: state.facilityDashboard,
  }),
  {
    ...actions,
  }
)(Facilities);
