import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import actionFilter from "../../../store/advancedfilter/action";
import { reset, getFormValues, isInvalid } from "redux-form";
import { store } from "../../../../src/index";
import { toast } from "react-toastify";
import FE_AdvanceFilter_NewTabs from "../../F&E/AdvanceFilter/FE_AdvanceFilter_NewTabs";
// import * as Permissions from "../../Permissions/PermissionValues";
// import AuthService from "../../Auth/AuthService";
export class TagsButtons extends Component {
  applyFilter() {
    const currentTab = this.props.filterState.activeTab;
    if (currentTab !== "0" && this.checkValidApply(currentTab)) {
      this.setFormValues(currentTab);
      var apply = {
        searchType: +this.props.filterState.activeTab,
        isApplied: true,
        isSearched: false,
      };
      let filterItems = this.getSelectedOptions(currentTab);

      apply.selectedItems = filterItems;

      if (_.isEmpty(this.props.filterState.filterData.searchApplied)) {
        this.props.filterState.filterData.searchApplied = [];
      }

      var applyIndex = _.findIndex(
        this.props.filterState.filterData.searchApplied,
        (o) => {
          return o.searchType === apply.searchType;
        }
      );
      if (applyIndex === -1) {
        this.props.filterState.filterData.searchApplied.push(apply);
      } else {
        this.props.filterState.filterData.searchApplied[applyIndex] = apply;
      }

      this.props.setFilterData({
        ...this.props.filterState.filterData,
      });
      this.props.filterState.filterData = Object.assign(
        {},
        this.props.filterState.filterData,
        {
          activeTab: currentTab,
        }
      );
      console.log(this.props.filterState.filterData);
      this.props.setCurrentActiveTab(currentTab);
      this.props.setAdvanceFilterLoader(true);
      this.props.getNewFilteredData(this.props.filterState.filterData, false);

      this.onReturnNo();
    }
  }
  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };
  getSelectedOptions = (currentTabKey) => {
    let fdata = this.props.filterState.filterData;

    let selectedVals = Object.keys(fdata)
      .filter((it) => it === this.getCurrentTabIdByName(currentTabKey))
      .reduce((obj, key) => {
        if (key == "phone") {
          let arr = [];
          if (fdata.phone && +fdata.phone !== 0) {
            arr.push(fdata.phone);
          }
          if (fdata.nullPrimaryPhone) {
            for (const item of fdata.nullPrimaryPhone) {
              if (item.checked) {
                arr.push(item.tag);
              }
            }
          }
          return arr;
        }
        if (key == "finance") {
          let dataObj = [];
          let obj = fdata[key];
          if (obj.duesBalance.checked) {
            dataObj.push("DuesBalance" + " : " + obj.duesBalance.duesBalance);
          }
          dataObj.push(
            "DuesRange : DuesRange " +
              obj.fromDuesAmount +
              "-" +
              obj.toDuesAmount
          );
          return dataObj;
        }
        if (key == "email") {
          let arr = [];
          if (fdata.email && +fdata.email !== 0) {
            arr.push(fdata.email);
          }
          if (fdata.nullPrimaryEmail) {
            for (const item of fdata.nullPrimaryEmail) {
              if (item.checked) {
                arr.push(item.tag);
              }
            }
          }
          return arr;
        }
        if (+fdata[key] !== 0 && fdata[key].constructor === Array) {
          if (
            fdata[key] &&
            +fdata[key] !== 0 &&
            fdata[key].some(
              (s) => typeof s !== "undefined" && s.constructor === Object
            )
          ) {
            if (key === "careerLevel") {
              obj[key] = fdata[key]
                .filter((it) => it.checked)
                .map((m) =>
                  Object.values(m).find((t) => typeof t[2] === "string")
                );
            } else if (key === "employer") {
              obj[key] = fdata[key]
                .filter((it) => it.checked)
                .map((m) =>
                  Object.values(m).find((t) => typeof t[2] === "string")
                );
            } else if (key === "congressionalDistrict") {
              obj[key] = fdata[key]
                .filter((it) => it.checked)
                .map(
                  (m) =>
                    "CD (" +
                    Object.values(m).find((t) => typeof t[2] === "string") +
                    ")"
                )
                .filter(function(el) {
                  return el;
                });
            } else if (key === "wallCalendar") {
              obj[key] = fdata.wallCalendar
                .filter((it) => it.checked)
                .map((m) => m.status)
                .filter(function(el) {
                  return el;
                });
            } else if (key === "officers") {
              var currentActive = fdata["officers"][0].positionName;
              var prefix = fdata["officers"][0].checked ? "Current " : "";
              obj[key] = fdata[key]
                .filter((it) => it.checked)
                .map((m) => {
                  if (m.positionName != currentActive) {
                    return (
                      prefix +
                      Object.values(m).find((t) => typeof t === "string")
                    );
                  }
                })
                .filter(function(el) {
                  return el;
                });
            } else {
              obj[key] = fdata[key]
                .filter((it) => it.checked)
                .map((m) =>
                  Object.values(m).find((t) => typeof t === "string")
                );
            }
            return Object.values(obj);
          } else if (fdata[key].some((s) => typeof s === "string")) {
            return Object.values(fdata[key]);
          }
        } else if (
          +fdata[key] !== 0 &&
          fdata[key].constructor === Object &&
          typeof fdata[key] !== "string"
        ) {
          obj = fdata[key];

          let selectedObjects = Object.keys(obj).map((item) => {
            if (key == "address" && item == "nullPrimaryAddress") {
              let arr = [];
              if (obj.nullPrimaryAddress) {
                for (const item of obj.nullPrimaryAddress) {
                  if (item.checked) {
                    arr.push(item.tag);
                  }
                }
              }
              return arr;
            }
            if (key == "address" && item == "zipWithout4") {
              let arr = [];
              if (obj.zipWithout4) {
                if (obj.zipWithout4.checked) {
                  arr.push(obj.zipWithout4.zipcodeWithout4);
                }
              }
              return arr;
            }
            if (
              key == "finance" &&
              (item == "fromDuesAmount" || item == "toDuesAmount")
            ) {
              let dataObj = {};

              dataObj[`${item}`] = obj[item];
              return `${item} : ${obj[item]}`;
            }
            if (obj[item] instanceof Date) {
              let dateObj = {};

              dateObj[`${item}`] = obj[item].toLocaleDateString();

              return dateObj;
            } else if (
              obj[item] &&
              +obj[item] !== 0 &&
              obj[item].constructor === Array &&
              obj[item].some((it) => it.checked)
            ) {
              return obj[item]
                .filter((it) => it.checked)
                .map((m) =>
                  Object.values(m).find((t) => typeof t === "string")
                );
            } else if (
              obj[item] &&
              +obj[item] !== 0 &&
              obj[item].constructor === Object
            ) {
              //Incase we have objec that contans check box total and tags for advance filter
              if (Object.keys(obj[item]).some((x) => x == "checked")) {
                if (obj[item].checked) {
                  return Object.values(obj[item]).filter(
                    (m) => typeof m === "string"
                  );
                }
              } else {
                return Object.keys(obj[item]).map(
                  (ky) => `${ky} : ${obj[item][ky]}`
                );
              }
            } else if (
              obj[item] &&
              +obj[item] !== 0 &&
              typeof obj[item] === "string"
            ) {
              let constainer = {};

              constainer[`${item}`] = obj[item];

              return Object.keys(constainer).map(
                (key) => `${key} : ${obj[key]}`
              );
            }

            // else if (obj[item] && +obj[item] !== 0) {

            //   return Object.keys(obj).map((key) => `${key} : ${obj[key]}`);
            // }
          });
          selectedObjects = selectedObjects.filter(
            (f) => typeof f !== "undefined"
          );

          if (
            selectedObjects.length > 0 &&
            selectedObjects.some(
              (f) => f.constructor === Object || f.constructor === Array
            )
          ) {
            let result = selectedObjects.map((item) => {
              return (item = Object.keys(item).map((key) => {
                if (!isNaN(key)) {
                  return item[key];
                } else {
                  return `${key} : ${item[key]}`;
                }
              }));
            });

            return result;
          } else {
            return Object.keys(obj).map((key) => `${key} : ${obj[key]}`);
          }
        } else if (typeof fdata[key] === "string") {
          let constainer = {};

          constainer[`${key}`] = fdata[key];
          obj = constainer;

          return Object.keys(obj).map((key) => `${obj[key]}`);
        }
      }, {});

    selectedVals = selectedVals
      ? selectedVals.filter((f) => typeof f !== "undefined")
      : [];
    let result = [].concat.apply([], selectedVals);
    if (selectedVals.some((s) => s.includes("From") || s.includes("To"))) {
      // Range Fields
      if (
        selectedVals.some((s) => s.includes("FromAge") || s.includes("ToAge"))
      ) {
        result = selectedVals
          .toString()
          .match(/\d+/g)
          .map(Number)
          .join(">")
          .split();
      } else {
        result = selectedVals
          .toString()
          .match(/\d+/g)
          .map(Number)
          .join("-")
          .split();
      }
    } else {
      result = result.map((m) => {
        if (m.includes(":")) {
          return m.split(": ")[1];
        } else {
          return m;
        }
      });
    }
    return result;
  };
  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };
  getCurrentTabIdByName = (id) => {
    id = parseInt(id);
    let tabName = "";
    switch (id) {
      case 1:
        tabName = "address";
        break;
      case 2:
        tabName = "careerLevel";
        break;
      case 3:
        tabName = "communication";
        break;
      case 4:
        tabName = "age";
        break;
      case 5:
        tabName = "dutyStation";
        break;
      case 6:
        tabName = "email";
        break;
      case 7:
        tabName = "memberECI";
        break;
      case 8:
        tabName = "facility";
        break;
      case 9:
        tabName = "memberStatus";
        break;
      case 10:
        tabName = "memberType";
        break;
      case 11:
        tabName = "region";
        break;
      case 12:
        tabName = "memberNumber";
        break;
      case 14:
        tabName = "name";
        break;
      case 15:
        tabName = "shirtSize";
        break;
      case 16:
        tabName = "gender";
        break;

      case 17:
        tabName = "pac";
        break;
      case 18:
        tabName = "drf";
        break;
      case 19:
        tabName = "dutyStation";
        break;
      case 20:
        tabName = "unit";
        break;
      case 21:
        tabName = "occupationSeries";
        break;
      case 22:
        tabName = "careerLevel";
        break;
      case 23:
        tabName = "payGrade";
        break;
      case 24:
        tabName = "flsaCategory";
        break;
      case 25:
        tabName = "communication";
        break;
      case 26:
        tabName = "address";
        break;
      case 27:
        tabName = "veteran";
        break;
      case 28:
        tabName = "retiredMilitary";
        break;
      case 29:
        tabName = "facilityRoutingSymbol";
        break;
      case 30:
        tabName = "orgCode";
        break;
      case 31:
        tabName = "hourlySalary";
        break;
      case 32:
        tabName = "annualSalary";
        break;
      case 33:
        tabName = "email";
        break;
      case 52:
        tabName = "facebook";
        break;
      case 34:
        tabName = "plannedRetirement";
        break;
      case 50:
        tabName = "mandatoryRetirement";
        break;
      case 35:
        tabName = "scd";
        break;
      case 36:
        tabName = "eodDate";
        break;
      case 37:
        tabName = "natcabuDate";
        break;
      case 38:
        tabName = "buDate";
        break;
      case 39:
        tabName = "unionStartDate";
        break;
      case 40:
        tabName = "unionJoinDate";
        break;
      case 41:
        tabName = "retirement";
        break;
      case 42:
        tabName = "teamPosition";
        break;
      case 43:
        tabName = "employer";
        break;
      case 44:
        tabName = "positionTypes";
        break;
      case 54:
        tabName = "teams";
        break;
      case 55:
        tabName = "activeTeamPositionDate";
        break;
      case 56:
        tabName = "congressionalDistrict";
        break;
      case 57:
        tabName = "wallCalendar";
        break;
      /// FE Member Advance Filter
      case parseInt(FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS):
        tabName = "fe_request_statuses";
        break;
      case parseInt(FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES):
        tabName = "fe_inventory_budgetlines";
        break;
      case parseInt(FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL):
        tabName = "fe_inv_book_vals";
        break;
      case parseInt(FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE):
        tabName = "fe_inventory_types";
        break;
      case parseInt(FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC):
        tabName = "fe_inventory_desc";
        break;
      case 51:
        tabName = "phone";
        break;
      case 53:
        tabName = "finance";
        break;
      default:
        tabName = "NaN";
        break;
    }

    return tabName;
  };

  onReturnNo = () => {
    /// API
    if (!_.isEmpty(this.props.filterState.filterData.searchApplied)) {
      this.props.getMemberTotalCount(this.props.filterState.filterData);
    } else {
      this.props.setReturnResultNo(0);
    }
  };

  resetFilter(activeTab) {
    this.removeFilter(activeTab);
    this.resetAllForms(activeTab);
    this.props.setAdvanceFilterLoader(true);
    this.props.setCurrentActiveTab(activeTab);
    this.props.getNewFilteredData(this.props.filterState.filterData, false);
    this.onReturnNo();
  }
  setFormValues(id) {
    switch (id) {
      case "14": //Name
        this.props.filterState.filterData.name = this.props.formValues.nameForm.values;

        break;
      case "4": //Age
        this.props.filterState.filterData.age = {
          FromAge: this.props.formValues.ageForm.values.Age.min,
          ToAge: this.props.formValues.ageForm.values.Age.max,
        };
        break;
      case "17": //PAC
        this.props.filterState.filterData.pac = {
          FromPAC: this.props.formValues.pacForm.values.PAC.min,
          ToPAC: this.props.formValues.pacForm.values.PAC.max,
        };
        break;

      case "18":
        this.props.filterState.filterData.drf = {
          FromDRF: this.props.formValues.drfForm.values.DRF.min,
          ToDRF: this.props.formValues.drfForm.values.DRF.max,
        };
        break;
      case "7": //ECI
        this.props.filterState.filterData.memberECI = this.props.formValues.memberECIForm.values.memberECI;

        break;
      case "12": // Member No
        this.props.filterState.filterData.memberNumber = this.props.formValues.memberNoForm.values.memberNumbers;
        break;
      case "29":
        this.props.filterState.filterData.facilityRoutingSymbol = this.props.formValues.facilityRoutingSymbolForm.values.FacilityRoutingSymbol;
        break;
      case "30":
        this.props.filterState.filterData.orgCode = this.props.formValues.orgCodeForm.values.OrgCode;
        break;
      case "31":
        this.props.filterState.filterData.hourlySalary = {
          FromHourlySalary: this.props.formValues.hourlySalaryForm.values
            .HourlySalary.min,
          ToHourlySalary: this.props.formValues.hourlySalaryForm.values
            .HourlySalary.max,
        };
        break;
      case "32":
        this.props.filterState.filterData.annualSalary = {
          FromAnnualSalary: this.props.formValues.annualSalaryForm.values
            .AnnualSalary.min,
          ToAnnualSalary: this.props.formValues.annualSalaryForm.values
            .AnnualSalary.max,
        };
        break;
      case "33":
        this.props.filterState.filterData.email = this.props.formValues.emailForm.values.Email;
        if (!_.isEmpty(this.props.filterState.filterData.nullPrimaryEmail)) {
          this.props.filterState.filterData.nullPrimaryEmail[0].checked = this.props.formValues.emailForm.values.NullPrimaryEmailYes;
          this.props.filterState.filterData.nullPrimaryEmail[1].checked = this.props.formValues.emailForm.values.NullPrimaryEmailNo;
        }

        break;
      case "52":
        this.props.filterState.filterData.facebook = this.props.formValues.facebookForm.values.Facebook;
        break;

      case "34":
        this.props.filterState.filterData.plannedRetirement = this.props.formValues.plannedRetirementForm.values;
        break;
      case "50":
        this.props.filterState.filterData.mandatoryRetirement = this.props.formValues.mandatoryRetirementForm.values;
        break;
      case "35":
        this.props.filterState.filterData.scd = this.props.formValues.sCDForm.values;
        break;

      case "36":
        this.props.filterState.filterData.eodDate = this.props.formValues.eODDateForm.values;
        break;

      case "37":
        this.props.filterState.filterData.natcabuDate = this.props.formValues.nATCABUDateForm.values;
        break;

      case "38":
        this.props.filterState.filterData.buDate = this.props.formValues.bUDateForm.values;
        break;

      case "39":
        this.props.filterState.filterData.unionStartDate = this.props.formValues.unionStartDateForm.values;
        break;

      case "40":
        this.props.filterState.filterData.unionJoinDate = this.props.formValues.unionJoinDateForm.values;
        break;
      case "41":
        this.props.filterState.filterData.retirement = this.props.formValues.retirementForm.values;
      case "42":
        this.props.filterState.filterData.teamPosition = this.props.formValues.teamPositionForm.values;
        break;
      case "55":
        this.props.filterState.filterData.activeTeamPositionDate = this.props.formValues.activeDatePositionForm.values;
        break;

      ////FE Equipment Advance Filter Tabs Apply Filter Work
      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC: //description
        this.props.filterState.filterData.fe_inventory_desc = this.props.formValues.fe_mem_descriptionForm.values.Description;

        break;
      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL: //BookValue
        this.props.filterState.filterData.fe_inv_book_vals = {
          FromBookValue: this.props.formValues.fe_mem_bookValsForm.values
            .BookValue.min,
          ToBookValue: this.props.formValues.fe_mem_bookValsForm.values
            .BookValue.max,
        };

        break;
      case "51":
        this.props.filterState.filterData.phone = this.props.formValues.phoneForm.values.Phone;
        if (!_.isEmpty(this.props.filterState.filterData.nullPrimaryPhone)) {
          this.props.filterState.filterData.nullPrimaryPhone[0].checked = this.props.formValues.phoneForm.values.NullPrimaryPhoneYes;
          this.props.filterState.filterData.nullPrimaryPhone[1].checked = this.props.formValues.phoneForm.values.NullPrimaryPhoneNo;
        }

        break;
      default:
        break;
    }
  }
  // Called from Reset Button
  removeFilter(removeId) {
    var removeIndex = _.findIndex(
      this.props.filterState.filterData.searchApplied,
      (o) => {
        return o.searchType === +removeId;
      }
    );
    if (removeIndex !== -1) {
      _.remove(this.props.filterState.filterData.searchApplied, {
        searchType: +removeId,
      });
    }
    switch (+removeId) {
      case 4:
        //filteredText = "Age";
        this.props.filterState.filterData.age = null;
        break;
      case 7:
        this.props.filterState.filterData.memberECI = [];
        break;
      case 8:
        //filteredText = "Facility";
        this.props.filterState.filterData.facility = this.props.filterState.filterData.facility.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 9:
        var apply = {
          searchType: 9,
          isApplied: true,
          selectedItems: ["Active"],
        };
        this.props.filterState.filterData.searchApplied.push(apply);
        this.props.filterState.filterData.memberStatus = this.props.filterState.filterData.memberStatus.map(
          (item) => {
            if (item.memberStatus === "Active") {
              item.checked = true;
            } else {
              item.checked = false;
            }
            return item;
          }
        );
        break;
      case 10:
        //filteredText = "Member Type";
        this.props.filterState.filterData.memberType = this.props.filterState.filterData.memberType.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 11:
        //filteredText = "Region";
        this.props.filterState.filterData.region = this.props.filterState.filterData.region.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 12:
        this.props.filterState.filterData.memberNumber = null;
        break;
      case 14:
        //filteredText = "Name";
        this.props.filterState.filterData.name = null;
        break;
      case 15:
        //filteredText = "Shirt Size";
        this.props.filterState.filterData.shirtSize = this.props.filterState.filterData.shirtSize.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 16:
        //filteredText = "Gender";
        this.props.filterState.filterData.gender = this.props.filterState.filterData.gender.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 17:
        //filteredText = "PAC";
        this.props.filterState.filterData.pac = null;
        break;
      case 18:
        //filteredText = "DRF";
        this.props.filterState.filterData.drf = null;
        break;
      case 19:
        this.props.filterState.filterData.dutyStation = this.props.filterState.filterData.dutyStation.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 20:
        this.props.filterState.filterData.unit = this.props.filterState.filterData.unit.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 21:
        this.props.filterState.filterData.occupationSeries = this.props.filterState.filterData.occupationSeries.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 22:
        this.props.filterState.filterData.careerLevel = this.props.filterState.filterData.careerLevel.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 23:
        this.props.filterState.filterData.payGrade = this.props.filterState.filterData.payGrade.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 24:
        this.props.filterState.filterData.flsaCategory = this.props.filterState.filterData.flsaCategory.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 25:
        this.props.filterState.filterData.communication = this.props.filterState.filterData.communication.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 26:
        this.props.filterState.filterData.address.addressTypes = this.props.filterState.filterData.address.addressTypes.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        this.props.filterState.filterData.address.states = this.props.filterState.filterData.address.states.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        this.props.filterState.filterData.address.verifiedAddress = this.props.filterState.filterData.address.verifiedAddress.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        this.props.filterState.filterData.address.nullPrimaryAddress = this.props.filterState.filterData.address.nullPrimaryAddress.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        this.props.filterState.filterData.address.zipWithout4.checked = false;
        this.props.filterState.filterData.address.city = "";
        this.props.filterState.filterData.address.country = "";
        this.props.filterState.filterData.address.zip = "";

        break;
      case 27:
        this.props.filterState.filterData.veteran = this.props.filterState.filterData.veteran.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 28:
        this.props.filterState.filterData.retiredMilitary = this.props.filterState.filterData.retiredMilitary.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 29:
        this.props.filterState.filterData.facilityRoutingSymbol = null;
        break;
      case 30:
        this.props.filterState.filterData.orgCode = null;
        break;
      case 31:
        this.props.filterState.filterData.hourlySalary = null;
        break;
      case 32:
        this.props.filterState.filterData.annualSalary = null;
        break;
      case 33:
        this.props.filterState.filterData.email = null;
        this.props.filterState.filterData.nullPrimaryEmail = this.props.filterState.filterData.nullPrimaryEmail.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 52:
        this.props.filterState.filterData.facebook = null;
        break;
      case 34:
        this.props.filterState.filterData.plannedRetirement = null;
        break;
      case 50:
        this.props.filterState.filterData.mandatoryRetirement = null;
        break;
      case 35:
        this.props.filterState.filterData.scd = null;
        break;
      case 36:
        this.props.filterState.filterData.eodDate = null;
        break;
      case 37:
        this.props.filterState.filterData.natcabuDate = null;
        break;
      case 38:
        this.props.filterState.filterData.buDate = null;
        break;
      case 39:
        this.props.filterState.filterData.unionStartDate = null;
        break;
      case 40:
        this.props.filterState.filterData.unionJoinDate = null;
        break;
      case 41:
        this.props.filterState.filterData.retirement = null;
        break;
      case 43:
        this.props.filterState.filterData.employer = this.props.filterState.filterData.employer.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case 56:
        this.props.filterState.filterData.congressionalDistrict = this.props.filterState.filterData.congressionalDistrict.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;

      case 57:
        this.props.filterState.filterData.wallCalendar = this.props.filterState.filterData.wallCalendar.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;

      // Advance filter tabs for Equipment
      // Called from Reset Button
      // Restting the advance filter

      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS:
        this.props.filterState.filterData.fe_request_statuses = this.props.filterState.filterData.fe_request_statuses.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE:
        this.props.filterState.filterData.fe_inventory_types = this.props.filterState.filterData.fe_inventory_types.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES:
        this.props.filterState.filterData.fe_inventory_budgetlines = this.props.filterState.filterData.fe_inventory_budgetlines.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
        break;
      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL:
        this.props.filterState.filterData.fe_inv_book_vals = null;
        break;

      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC:
        this.props.filterState.filterData.fe_inventory_desc = null;
        break;
      case 51:
        this.props.filterState.filterData.phone = null;
        this.props.filterState.filterData.nullPrimaryPhone = this.props.filterState.filterData.nullPrimaryPhone.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );

        break;
      case 53:
        this.props.filterState.filterData.finance.fromDuesAmount = null;
        this.props.filterState.filterData.finance.toDuesAmount = null;
        this.props.filterState.filterData.finance.duesBalance.checked = false;

        break;

      default:
        break;
    }

    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
    //this.onReturnNo();
  }

  isAnyFormInvalid(formName) {
    if (isInvalid(formName)(this.props.currentState)) {
      return true;
    } else {
      return false;
    }
  }

  resetAllForms(activeTab) {
    switch (activeTab) {
      case "18":
        store.dispatch(reset("drfForm"));
        break;
      case "17":
        store.dispatch(reset("pacForm"));
        break;
      case "4":
        store.dispatch(reset("ageForm"));
        break;
      case "14":
        store.dispatch(reset("nameForm"));
        break;
      case "7":
        store.dispatch(reset("memberECIForm"));
        break;
      case "12":
        store.dispatch(reset("memberNoForm"));
        break;
      case "29":
        store.dispatch(reset("facilityRoutingSymbolForm"));
        break;
      case "30":
        store.dispatch(reset("orgCodeForm"));
        break;
      case "31":
        store.dispatch(reset("hourlySalaryForm"));
        break;
      case "32":
        store.dispatch(reset("annualSalaryForm"));
        break;
      case "33":
        store.dispatch(reset("emailForm"));
        break;
      case "52":
        store.dispatch(reset("facebookForm"));
        break;
      case "34":
        store.dispatch(reset("plannedRetirementForm"));
        break;
      case "50":
        store.dispatch(reset("mandatoryRetirementForm"));
        break;
      case "35":
        store.dispatch(reset("sCDForm"));
        break;
      case "36":
        store.dispatch(reset("eODDateForm"));
        break;
      case "37":
        store.dispatch(reset("nATCABUDateForm"));
        break;
      case "38":
        store.dispatch(reset("bUDateForm"));
        break;
      case "39":
        store.dispatch(reset("unionStartDateForm"));
        break;
      case "40":
        store.dispatch(reset("unionJoinDateForm"));
        break;
      case "41":
        store.dispatch(reset("retirementForm"));
        break;
      case "42":
        store.dispatch(reset("teamPositionForm"));
        break;
      case "55":
        store.dispatch(reset("activeDatePositionForm"));
        break;
      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_BOOKVAL:
        store.dispatch(reset("fe_mem_bookValsForm"));
        break;

      case FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC:
        store.dispatch(reset("fe_mem_descriptionForm"));
        break;
      case "51":
        store.dispatch(reset("phoneForm"));
        break;
      default:
        break;
    }
  }
  checkValidApply = (currentTab) => {
    const Forms = {
      Age: "ageForm",
      ECI: "memberECIForm",
      MemberNo: "memberNoForm",
      PAC: "pacForm",
      DRF: "drfForm",
      NAME: "nameForm",
      FacilityRoutingSymbolForm: "facilityRoutingSymbolForm",
      OrgCode: "orgCodeForm",
      HourlySalaryForm: "hourlySalaryForm",
      AnnualSalaryForm: "annualSalaryForm",
      EmailForm: "emailForm",
      FacebookForm: "facebookForm",
      PhoneForm: "phoneForm",
      PlannedRetirementForm: "plannedRetirementForm",
      RetirementForm: "retirementForm",
      SCDForm: "sCDForm",
      EODDateForm: "eODDateForm",
      NATCABUDateForm: "nATCABUDateForm",
      BUDateForm: "bUDateForm",
      UnionStartDateForm: "unionStartDateForm",
      UnionJoinDateForm: "unionJoinDateForm",
      TeamPositionForm: "teamPositionForm",
      ActiveDatePositionForm: "activeDatePositionForm",
      EquipmentBookVal: "fe_mem_bookValsForm",
      EquipmentDescription: "fe_mem_descriptionForm",
    };

    if (currentTab === "9") {
      ///Member Status
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.memberStatus, "checked")
        )
      ) {
        toast.warn("Select Status.");
        return false;
      }
    }

    if (currentTab === "10") {
      ///Member Type
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.memberType, "checked")
        )
      ) {
        toast.warn("Select Member Type.");
        return false;
      }
    }

    if (currentTab === "11") {
      ///Region
      if (
        _.isEmpty(_.filter(this.props.filterState.filterData.region, "checked"))
      ) {
        toast.warn("Select Region.");
        return false;
      }
    }

    if (currentTab === "8") {
      ///Facility
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.facility, "checked")
        )
      ) {
        toast.warn("Select Facility.");
        return false;
      }
    }
    if (currentTab === "16") {
      ///Gender
      if (
        _.isEmpty(_.filter(this.props.filterState.filterData.gender, "checked"))
      ) {
        toast.warn("Select Gender.");
        return false;
      }
    }
    if (currentTab === "15") {
      ///ShirtSize
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.shirtSize, "checked")
        )
      ) {
        toast.warn("Select Shirtsize.");
        return false;
      }
    }

    if (currentTab === "14") {
      // name tab
      const nameValues = getFormValues(Forms.NAME)(this.props.currentState);
      if (_.isEmpty(nameValues)) {
        toast.warn("Select Name.");
        return false;
      }
    }

    if (currentTab === "4") {
      // Age tab
      const formValues = getFormValues(Forms.Age)(this.props.currentState);
      if (_.isEmpty(formValues)) {
        toast.warn("Select Age.");
        return false;
      }
    }

    if (currentTab === "17") {
      // PAC tab
      const formValues = getFormValues(Forms.PAC)(this.props.currentState);
      if (_.isEmpty(formValues)) {
        toast.warn("Select PAC.");
        return false;
      }
    }

    if (currentTab === "18") {
      // DRF tab
      const formValues = getFormValues(Forms.DRF)(this.props.currentState);
      if (_.isEmpty(formValues)) {
        toast.warn("Select DRF.");
        return false;
      }
    }

    if (currentTab === "12") {
      const MemberNoValues = getFormValues("memberNoForm")(
        this.props.currentState
      );
      if (
        MemberNoValues &&
        (_.isEmpty(MemberNoValues.memberNumbers[0]) ||
          !MemberNoValues.memberNumbers.every((s) => typeof s === "string"))
      ) {
        toast.warn("Member No field is empty.");
        return false;
      }
    }
    if (currentTab === "7") {
      const MemberECIValues = getFormValues("memberECIForm")(
        this.props.currentState
      );
      if (
        MemberECIValues &&
        (_.isEmpty(MemberECIValues.memberECI[0]) ||
          !MemberECIValues.memberECI.every((s) => typeof s === "string"))
      ) {
        toast.warn("Member ECI field is empty.");
        return false;
      }
    }

    if (currentTab === "18") {
      // DRF tab
      const formValues = getFormValues(Forms.DRF)(this.props.currentState);
      if (_.isEmpty(formValues)) {
        toast.warn("Select DRF.");
        return false;
      }
    }

    if (currentTab === "19") {
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.dutyStation, "checked")
        )
      ) {
        toast.warn("Select Duty Station.");
        return false;
      }
    }

    if (currentTab === "20") {
      if (
        _.isEmpty(_.filter(this.props.filterState.filterData.unit, "checked"))
      ) {
        toast.warn("Select Unit.");
        return false;
      }
    }

    if (currentTab === "21") {
      if (
        _.isEmpty(
          _.filter(
            this.props.filterState.filterData.occupationSeries,
            "checked"
          )
        )
      ) {
        toast.warn("Select Occupation Series.");
        return false;
      }
    }

    if (currentTab === "22") {
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.careerLevel, "checked")
        )
      ) {
        toast.warn("Select Career Level.");
        return false;
      }
    }

    if (currentTab === "23") {
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.payGrade, "checked")
        )
      ) {
        toast.warn("Select Pay Grade.");
        return false;
      }
    }

    if (currentTab === "24") {
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.flsaCategory, "checked")
        )
      ) {
        toast.warn("Select FLSA Category.");
        return false;
      }
    }

    if (currentTab === "25") {
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.communication, "checked")
        )
      ) {
        toast.warn("Select Communication.");
        return false;
      }
    }

    if (currentTab === "26") {
      if (
        !this.props.filterState.filterData.address.zipWithout4.checked &&
        _.isEmpty(
          _.filter(this.props.filterState.filterData.address.states, "checked")
        ) &&
        _.isEmpty(
          _.filter(
            this.props.filterState.filterData.address.addressTypes,
            "checked"
          )
        ) &&
        _.isEmpty(
          _.filter(
            this.props.filterState.filterData.address.verifiedAddress,
            "checked"
          )
        ) &&
        _.isEmpty(
          _.filter(
            this.props.filterState.filterData.address.nullPrimaryAddress,
            "checked"
          )
        ) &&
        _.isEmpty(this.props.filterState.filterData.address.city) &&
        _.isEmpty(this.props.filterState.filterData.address.country) &&
        _.isEmpty(this.props.filterState.filterData.address.zip)
      ) {
        toast.warn("Please enter any one address field.");
        return false;
      }
    }

    if (currentTab === "27") {
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.veteran, "checked")
        )
      ) {
        toast.warn("Select Veteran.");
        return false;
      }
    }

    if (currentTab === "28") {
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.retiredMilitary, "checked")
        )
      ) {
        toast.warn("Select Retired Military.");
        return false;
      }
    }

    if (currentTab === "29") {
      const formValues = getFormValues(Forms.FacilityRoutingSymbolForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Facility Routing Symbol.");
        return false;
      }
    }

    if (currentTab === "30") {
      const formValues = getFormValues(Forms.OrgCode)(this.props.currentState);
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Org. Code.");
        return false;
      }
    }

    if (currentTab === "31") {
      const formValues = getFormValues(Forms.HourlySalaryForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Hourly Salary.");
        return false;
      }
      if (formValues.HourlySalary) {
        if (
          Number.parseInt(formValues.HourlySalary.min) >
          Number.parseInt(formValues.HourlySalary.max)
        ) {
          toast.warn(
            "Min Hourly Salary should be less than Max Hourly Salary."
          );
          return false;
        }
      }
    }

    if (currentTab === "32") {
      const formValues = getFormValues(Forms.AnnualSalaryForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Annual Salary.");
        return false;
      }
      if (formValues.AnnualSalary) {
        if (
          Number.parseInt(formValues.AnnualSalary.min) >
          Number.parseInt(formValues.AnnualSalary.max)
        ) {
          toast.warn(
            "Min Annual Salary should be less than Max Annual Salary."
          );
          return false;
        }
      }
    }

    if (currentTab === "33") {
      const formValues = getFormValues(Forms.EmailForm)(
        this.props.currentState
      );

      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Email Or select the checkbox.");
        return false;
      }
    }
    if (currentTab === "52") {
      const formValues = getFormValues(Forms.FacebookForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Facebook.");
        return false;
      }
    }
    if (currentTab === "51") {
      const formValues = getFormValues(Forms.PhoneForm)(
        this.props.currentState
      );
      console.log(formValues, "Phone Advanced Filter");
      let PhoneReg = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
      if (formValues) {
        //if (formValues.Phone === "(___) ___-____" || formValues.Phone === "") {
        //    toast.warn("Please enter Phone.");
        //    return false;
        //} else
        if (
          formValues.Phone &&
          formValues.Phone !== "(___) ___-____" &&
          formValues.Phone !== ""
        ) {
          if (PhoneReg.test(formValues.Phone)) {
          } else {
            toast.warn("Please enter valid Phone.");
            return false;
          }
        } else if (
          (formValues.NullPrimaryPhoneYes == null ||
            formValues.NullPrimaryPhoneYes == false) &&
          (formValues.NullPrimaryPhoneNo == null ||
            formValues.NullPrimaryPhoneNo == false)
        ) {
          toast.warn("Please enter valid Phone OR select the checkbox.");
          return false;
        }
      } else {
        toast.warn("In valid data.");
        return false;
      }
    }

    if (currentTab === "34") {
      const formValues = getFormValues(Forms.PlannedRetirementForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Mandatory Retirement date.");
        return false;
      }
    }

    if (currentTab === "41") {
      const formValues = getFormValues(Forms.RetirementForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Retirement date.");
        return false;
      }
    }

    if (currentTab === "35") {
      const formValues = getFormValues(Forms.SCDForm)(this.props.currentState);
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter SCD.");
        return false;
      }
    }

    if (currentTab === "36") {
      const formValues = getFormValues(Forms.EODDateForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter EOD Date.");
        return false;
      }
    }

    if (currentTab === "37") {
      const formValues = getFormValues(Forms.NATCABUDateForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter NATCA BU Date.");
        return false;
      }
    }

    if (currentTab === "38") {
      const formValues = getFormValues(Forms.BUDateForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter BU Date.");
        return false;
      }
    }

    if (currentTab === "39") {
      const formValues = getFormValues(Forms.UnionStartDateForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Union Start Date.");
        return false;
      }
    }

    if (currentTab === "40") {
      const formValues = getFormValues(Forms.UnionJoinDateForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Union Join Date.");
        return false;
      }
    }
    if (currentTab === "42") {
      const formValues = getFormValues(Forms.TeamPositionForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Team Position.");
        return false;
      }
    }
    if (currentTab === "55") {
      const formValues = getFormValues(Forms.ActiveDatePositionForm)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter From Date or To Date.");
        return false;
      }
    }
    if (currentTab === "43") {
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.employer, "checked")
        )
      ) {
        toast.warn("Select employer.");
        return false;
      }
    }
    const formValues = getFormValues("ageForm")(this.props.currentState);
    if (formValues) {
      if (formValues.FromAge && formValues.ToAge) {
        if (
          Number.parseInt(formValues.FromAge) >
          Number.parseInt(formValues.ToAge)
        ) {
          toast.warn("Min Age should be less than Max Age.");
          return false;
        }
      }
    }

    ////##########Empty Checks for Equipment Advance filter tabs###########///////

    if (currentTab === FE_AdvanceFilter_NewTabs.MEM_AF_INV_STATUS) {
      if (
        _.isEmpty(
          _.filter(
            this.props.filterState.filterData.fe_request_statuses,
            "checked"
          )
        )
      ) {
        toast.warn("Select Equipment Status.");
        return false;
      }
    }
    if (currentTab === FE_AdvanceFilter_NewTabs.MEM_AF_INV_BUDGETLINES) {
      if (
        _.isEmpty(
          _.filter(
            this.props.filterState.filterData.fe_inventory_budgetlines,
            "checked"
          )
        )
      ) {
        toast.warn("Select Equipment Budget Line.");
        return false;
      }
    }
    const equipmentFormValues = getFormValues(Forms.EquipmentBookVal)(
      this.props.currentState
    );
    if (equipmentFormValues) {
      if (
        equipmentFormValues.BookValue.min &&
        equipmentFormValues.BookValue.max
      ) {
        if (
          Number.parseInt(equipmentFormValues.BookValue.min) >
          Number.parseInt(equipmentFormValues.BookValue.max)
        ) {
          toast.warn("Min Values should be less than Max Value.");
          return false;
        }
      }
    }
    if (currentTab === FE_AdvanceFilter_NewTabs.MEM_AF_INV_TYPE) {
      if (
        _.isEmpty(
          _.filter(
            this.props.filterState.filterData.fe_inventory_types,
            "checked"
          )
        )
      ) {
        toast.warn("Select Equipment Type.");
        return false;
      }
    }

    if (currentTab === FE_AdvanceFilter_NewTabs.MEM_AF_INV_DESC) {
      const formValues = getFormValues(Forms.EquipmentDescription)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Equipment Description.");
        return false;
      }
    }
    if (currentTab === "53") {
      if (
        !this.props.filterState.filterData.duesMax &&
        this.props.filterState.filterData.finance.duesBalance.checked == false
      ) {
        toast.warn("Invalid data for the finance dues!");
        return false;
      }
      if (
        this.props.filterState.filterData.duesMax &&
        !this.props.filterState.filterData.finance.fromDuesAmount &&
        !this.props.filterState.filterData.finance.toDuesAmount
      ) {
        this.props.filterState.filterData.finance.toDuesAmount = this.props.filterState.filterData.duesMax;
        this.props.filterState.filterData.finance.fromDuesAmount = 0;
      }
    }
    if (currentTab === "56") {
      if (
        _.isEmpty(
          _.filter(
            this.props.filterState.filterData.congressionalDistrict,
            "checked"
          )
        )
      ) {
        toast.warn("Select Congressional District.");
        return false;
      }
    }
    if (currentTab === "57") {
      if (
        _.isEmpty(
          _.filter(this.props.filterState.filterData.wallCalendar, "checked")
        )
      ) {
        toast.warn("Select the Wall calendar status.");
        return false;
      }
    }
    let result = false;
    _.forEach(Forms, (value) => {
      if (this.isAnyFormInvalid(value)) {
        result = true;
      }
    });

    if (result) {
      return false;
    }

    return true;
  };
  render() {
    const loadingBtn = (
      <button class="btn btn-success mt-15 mr-2 applyfilter" type="button">
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </button>
    );
    return (
      <>
        {!this.props.filterState.loadingAdvanceFilter &&
          window.innerWidth > 897 && (
            <button
              type="button"
              disabled={this.props.dontAllowEditFilter}
              onClick={() => this.applyFilter()}
              className={`btn btn-success mt-15 mr-2 applyfilter ${this.props
                .isMobile && "btn-filter-sm mobileHeadBtns"}`}
            >
              Apply Filter
            </button>
          )}

        {window.innerWidth <= 897 && (
          <button
            type="button"
            disabled={this.props.dontAllowEditFilter}
            onClick={() => this.applyFilter()}
            className={`btn btn-success mt-15 mr-2 applyfilter ${this.props
              .isMobile && "btn-filter-sm mobileHeadBtns"}`}
          >
            Apply Filter
          </button>
        )}
        {this.props.filterState.loadingAdvanceFilter &&
          window.innerWidth > 897 &&
          loadingBtn}
        <button
          type="button"
          onClick={() => this.resetFilter(this.props.filterState.activeTab)}
          disabled={this.props.dontAllowEditFilter}
          className={`btn btn-primary mr-2 mb-1 float-right ${this.props
            .isMobile && "btn-filter-sm mobileHeadBtns"}`}
        >
          Reset
        </button>
      </>
    );
  }
}

export default connect(
  (state) => ({
    filterState: state.advancedFilter,
    formValues: state.form,
    currentState: state,
  }),
  {
    ...actionFilter,
  }
)(TagsButtons);
