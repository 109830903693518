import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import MailGunEmailTypes from "../../../Email/Enums/MailGunEmailTypes";
import { Dropdown } from "react-bootstrap";
import { Row } from "reactstrap";

const EMAIL_LIST_ROUTES = {
  [MailGunEmailTypes.Facility]: (entityKey) =>
    `/directory/facility/view/${entityKey}?defaultAccordion=emailList`,
  [MailGunEmailTypes.Member]: (entityKey) =>
    `/directory/myfilters?showEmailConfigModal=${entityKey}`,
  [MailGunEmailTypes.FilterUpdateSubscribers]: (entityKey) =>
    `/directory/myfilters?showEmailConfigModal=${entityKey}`,
  [MailGunEmailTypes.Team]: (entityKey) =>
    `/permissions/teams/teamDetail/${entityKey}?showEmailConfigModal=true`,
  [MailGunEmailTypes.Registrants]: (entityKey) =>
    `/admin/requests?showEmailConfigModal=${entityKey}`,
};

const EmailListRow = ({
  email,
  member,
  loggedInUserId,
  match,
  onUnsubscribe,
  onResubscribe,
  history,
}) => {
  const isOwner = email.ownerId && email.ownerId.includes(member.id);
  const hasEditPermission =
    isOwner ||
    AuthService.canSPUpdate(
      PermissionFeature.EmailListsLists,
      email.national,
      email.regionIds,
      email.regionIds
    ) ||
    loggedInUserId === Number(match.params.id || 0);

  const handleRowClick = () => {
    if (!isOwner) return;

    const route =
      EMAIL_LIST_ROUTES[email.email_List_Type_Id] &&
      EMAIL_LIST_ROUTES[email.email_List_Type_Id](email.entity_Key);
    if (route) {
      history.push(route, { emailListDetails: { ...email } });
    }
  };

  const renderAction = () => {
    if (!hasEditPermission) {
      return <label>{email.subscribed ? "UnSubscribe" : "ReSubscribe"}</label>;
    }

    return email.subscribed ? (
      <button
        className="btn btn-danger btnElAction"
        onClick={(e) => {
          e.preventDefault();
          onUnsubscribe(email);
          e.stopPropagation();
        }}
      >
        UnSubscribe
      </button>
    ) : (
      <button
        className="btn btn-success btnElAction"
        onClick={(e) => {
          e.preventDefault();
          onResubscribe(email);
          e.stopPropagation();
        }}
      >
        ReSubscribe
      </button>
    );
  };

  return (
    <tr className={isOwner ? "c-pointer" : ""} onClick={handleRowClick}>
      <td>
        {email.email_Address}{" "}
        {isOwner && (
          <i className="text-primary fas fa-arrow-circle-right ms-2" />
        )}
      </td>
      <td>{renderAction()}</td>
    </tr>
  );
};

const PAGE_SIZE_OPTIONS = [10, 25];

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  totalItems,
  startIndex,
  endIndex,
}) => {
  const pages = [...Array(totalPages).keys()].map((i) => i + 1);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        Show:
        <Dropdown className="ms-2">
          <Dropdown.Toggle
            variant="light"
            size="sm"
            style={{
              minWidth: "60px",
              backgroundColor: "#f8f9fa",
              borderColor: "#dee2e6",
            }}
          >
            {pageSize}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {PAGE_SIZE_OPTIONS.map((size) => (
              <Dropdown.Item key={size} onClick={() => onPageSizeChange(size)}>
                {size}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="text-muted">
        {startIndex + 1} - {endIndex} of {totalItems}
      </div>

      <nav aria-label="Email list pagination">
        <ul className="pagination justify-content-end mb-0">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
          </li>

          {pages.map((page) => (
            <li
              key={page}
              className={`page-item ${currentPage === page ? "active" : ""}`}
            >
              <button className="page-link" onClick={() => onPageChange(page)}>
                {page}
              </button>
            </li>
          ))}

          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const EmailListSection = ({
  emailHistoryAvail,
  showEmailHistory,
  handleShowAllEmails,
  handleLessAllEmails,
  emailListDisplayer,
  member,
  loggedInUserId,
  toggleConfirm_unsubscribe,
  setListName,
  ReSubscribedEmailList,
  history,
  match,
  isLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return emailListDisplayer
      ? emailListDisplayer.slice(startIndex, endIndex)
      : [];
  }, [emailListDisplayer, currentPage, pageSize]);

  const totalPages = useMemo(
    () =>
      Math.ceil(
        ((emailListDisplayer && emailListDisplayer.length) || 0) / pageSize
      ),
    [emailListDisplayer, pageSize]
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  if (!AuthService.canMemberView(PermissionFeature.EmailListsLists)) {
    return null;
  }

  return (
    <div className="card directory-card border-rounded shadow">
      <h5 className="card-header mt-0">
        <i className="mdi mdi-view-list me-2" />
        Email List
      </h5>
      <div className="card-body pb-3 pt-1">
        {emailHistoryAvail && (
          <div className="text-end mb-3">
            <button
              className="btn btn-link float-right"
              onClick={() =>
                showEmailHistory ? handleShowAllEmails() : handleLessAllEmails()
              }
            >
              {showEmailHistory ? "Show Unsubscribed" : "Hide Unsubscribed"}
            </button>
          </div>
        )}

        <table className="table table-striped table-hover table-bordered mb-0">
          <thead>
            <tr>
              <th>Subscribed List</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="2" className="text-center">
                  <div
                    class="m-3 position-relative d-block mx-auto spinner-border text-info"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : (
              paginatedData.map((email, index) => (
                <EmailListRow
                  key={index}
                  email={email}
                  member={member}
                  loggedInUserId={loggedInUserId}
                  match={match}
                  onUnsubscribe={(row) => {
                    let { email_Address } = row;
                    toggleConfirm_unsubscribe(row);
                    setListName(email_Address);
                  }}
                  onResubscribe={ReSubscribedEmailList}
                  history={history}
                />
              ))
            )}
          </tbody>
        </table>

        {emailListDisplayer && emailListDisplayer.length > 10 && (
          <div className="mt-3">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
              totalItems={
                (emailListDisplayer && emailListDisplayer.length) || 0
              }
              startIndex={(currentPage - 1) * pageSize}
              endIndex={Math.min(
                currentPage * pageSize,
                (emailListDisplayer && emailListDisplayer.length) || 0
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

EmailListSection.propTypes = {
  emailHistoryAvail: PropTypes.bool,
  showEmailHistory: PropTypes.bool,
  handleShowAllEmails: PropTypes.func.isRequired,
  handleLessAllEmails: PropTypes.func.isRequired,
  emailListDisplayer: PropTypes.array,
  member: PropTypes.object.isRequired,
  loggedInUserId: PropTypes.number,
  toggleConfirm_unsubscribe: PropTypes.func.isRequired,
  setListName: PropTypes.func.isRequired,
  ReSubscribedEmailList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
  startIndex: PropTypes.number.isRequired,
  endIndex: PropTypes.number.isRequired,
};

export default EmailListSection;
