const MailGunEmailTypes = Object.freeze({
  Area: 1,
  Facility: 2,
  Region: 3,
  Team: 4,
  Member: 5, // Save Search
  Registrants: 6, //For Registrations Module Registration
  FilterUpdateSubscribers: 7, //Used for Filter Updates Subscribers in SP Response
});
export default MailGunEmailTypes;
